<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF FLOATING MEMBERS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :height="height"
        :headers="headers"
        :items="list_of_new_member"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="headline">TOTAL: {{list_of_new_member.length}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <div
            v-if="item.type_of_member === 'Volunteer'||item.type_of_member === 'Beneficiary'||item.type_of_member === 'Scholar'">
          </div>
          <div v-else-if="item.paid === 1">
            <v-chip color="success" dark>PAID</v-chip>
          </div>
          <div v-else>
            <v-chip color="error" dark>UNPAID</v-chip>
          </div>
        </template>
        <template v-slot:item.no="{ item }">
          {{list_of_new_member.map(function(x) {return x.id; }).indexOf(item.id)+1}}
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        can_print: false,
        list_of_new_member: [],

        type_of_member: 'All',
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'No', value: 'no'},
          {text: 'CMF #', value: 'cmf_no'},
          {text: 'Effective Date', value: 'date_accomplished'},
          {text: 'Last Name', value: 'last_name'},
          {text: 'First Name', value: 'first_name'},
          {text: 'Status', value: 'status', sortable: false},
        ],
        height: 430
      }
    },
    mounted() {
      this.data_table_loading = true
      this.list_of_floating_members()
        .then((response) => {
          this.can_print = true
          this.list_of_new_member = response.data
          this.data_table_loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.list_of_new_member = []
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('members_information', ['list_of_floating_members']),
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'No', alignment: 'center', style: 'label'},
            {text: 'Control No', alignment: 'center', style: 'label'},
            {text: 'Effective Date', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Status', alignment: 'center', style: 'label'},
          ]
        )

        if (this.list_of_new_member.length > 0) {
          this.list_of_new_member.forEach(function (item, index) {
            var status = 'PAID'
            if (item.type_of_member === 'Volunteer') {
              status = ''
            } else if (item.paid === 0) {
              status = 'UNPAID'
            }
            array.push(
              [
                {text: index + 1, alignment: 'center'},
                {text: item.cmf_no, alignment: 'left'},
                {text: item.date_accomplished, alignment: 'left'},
                {
                  text: item.last_name + ', ' + item.first_name + ' ' + item.middle_name,
                  alignment: 'left'
                },
                {text: status, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. NA CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF FLOATING MEMBERS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [30, 50, 70, 230, 50],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
